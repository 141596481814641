import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';

import { useverifyVendorPinCodeMutation } from '../../apollo';
import { getErrorMessage } from '../../helpers';
import Header from '../Header';
import { LoadingSpinner } from '../LoadingSpinner';
import VendorPinInfoModal from '../VendorPinInfoModal';

interface VendorPinAccessVerificationProps {
  isVirtualDirectoryEnabled: boolean;
  onVerified: () => void;
}

export const VendorPinAccessVerification: React.FC<VendorPinAccessVerificationProps> = (props) => {
  const navigate = useNavigate();
  const accessPointId = useParams().accessPointId as string;
  const { isVirtualDirectoryEnabled, onVerified } = props;

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [verifyVendorPinCode, { loading }] = useverifyVendorPinCodeMutation();
  const regex = /^[a-z0-9]+$/i;
  const inputs = document.querySelectorAll('input[data-id]');

  useEffect(() => {
    const thirdElement = document.querySelector('input[data-id="2"]');
    thirdElement?.insertAdjacentHTML(
      'afterend',
      '<p class="h1" style="font-size: 50px; font-weight: 300; line-height :50px">-</p>',
    );
  }, []);

  useEffect(() => {
    if (errorMessage && verificationCode.length < 6) {
      setErrorMessage(null);
    }
  }, [verificationCode, errorMessage]);

  useEffect(() => {
    const handleBeforeInput = (event: Event) => {
      const inputEvent = event as InputEvent;
      const isAlphanumeric = /^[a-z0-9]*$/i.test(inputEvent.data ?? '');
      if (!isAlphanumeric) {
        event.preventDefault();
      }
    };

    inputs.forEach((input: Element) => {
      input.addEventListener('beforeinput', handleBeforeInput);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('beforeinput', handleBeforeInput);
      });
    };
  }, [verificationCode]);

  return (
    <div>
      <Header
        title="Vendor Access PIN"
        leftElement={
          <button className="button-with-icon" onClick={() => navigate(-1)}>
            <img src="/images/arrow-left.png" alt="" className="arrow-icon" />
          </button>
        }
        rightElement={
          <button className="button-with-icon" onClick={() => setInfoModalOpen(true)}>
            <img src="/images/question.svg" alt="" className="info-icon" />
          </button>
        }
        style={{ display: 'flex', flex: 1 }}
      />
      <div className="display-flex-wrapper">
        <div className="verification-wrapper">
          <p className="h2 text-dark" style={{ fontWeight: 600 }}>ENTER VENDOR PIN</p>
          <div className="spacer"/>
          <ReactCodeInput
            type="text"
            className="verification-input"
            fieldWidth={38}
            onChange={(val) => {
              if (regex.test(val)) {
                setVerificationCode(val);
              }
            }}
            onComplete={async (val) => {
              const code = `${val.toUpperCase().substring(0, 3)}-${val.toUpperCase().substring(3)}`;

              try {
                const result = await verifyVendorPinCode({
                  variables: {
                    input: { accessPointId, code },
                  },
                });

                if (result.data?.vendorPinUserRole) {
                  onVerified();
                }
              } catch (error) {
                setErrorMessage(getErrorMessage(error as Error));
              }
            }}
          />
          {loading && <LoadingSpinner />}
          <div className="spacer"/>
          <div className="spacer"/>
          <span className="error-wrapper">
            {errorMessage}
          </span>
        </div>
        <VendorPinInfoModal
          closeModal={() => setInfoModalOpen(false)}
          isOpen={infoModalOpen}
          isVirtualDirectoryEnabled={isVirtualDirectoryEnabled}
        />
      </div>
    </div>
  );
};

export default VendorPinAccessVerification;
